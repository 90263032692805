<template>
  <div>
    <v-card class="segmented" flat>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h5>{{ $t('workflows.title') }} - {{ $t('overview') }}</h5>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title>
        <v-row dense>
          <v-col cols="2"> {{ $t('workflows.statusId') }} </v-col>
          <v-col>
            {{ $t('feedbacks.feedback') }}-{{ $t('feedbacks.type_short') }}
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>
      </v-card-title>
      <template v-for="(workflow, index) in sortedWorkflowSettings">
        <v-row dense :key="index">
          <v-col cols="2" @click="transition(workflow.id)">
            {{ workflow.processType }}</v-col
          >
          <v-col>
            {{ $t(`feedbacks.processTypes.${workflow.processType}`) }}</v-col
          >
          <v-col cols="3">
            <v-btn
              elevation="2"
              large
              :to="{ name: 'Workflow', params: { id: workflow.id } }"
            >
              {{ $t('edit') }}
            </v-btn>
            <v-btn elevation="2" large @click="deleteWorkflow(workflow.id)">
              {{ $t('delete') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-btn elevation="2" large @click="createWorkflow"
        >{{ $t('workflows.workflowCreate') }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { feedbackEnums } from '@/enums/feedbacks.js';
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      workflowSettings: 'workflowSettings'
    }),

    sortedWorkflowSettings() {
      return [...this.workflowSettings].sort(
        (x, y) => x.processType - y.processType
      );
    }
  },

  methods: {
    ...mapActions({
      saveWorkflow: 'workflows/saveWorkflow',
      deleteWorkflow: 'workflows/deleteWorkflow'
    }),

    async createWorkflow() {
      const toCreate = -1;
      const newWorkflow = {
        processType: toCreate.toString(),
        createdBy: [],
        hasSelfEvaluation: false,
        hasSupervisorEvaluation: false,
        hasDialog: false,
        hasConclusion: false,
        conclusionType: 'Comparison',
        conclusionForm: null,
        initialStatus: '0',
        selfEvaluationTitle: [],
        supervisorEvaluationTitle: [],
        conclusionTitle: [],
        statusList: [
          {
            statusId: '0',
            translations: [
              {
                languageKey: 'de-DE',
                translation: 'Neuer Status'
              }
            ],
            descriptions: [
              {
                languageKey: 'de-DE',
                translation: 'Ein neuer Status'
              }
            ],
            statusChanges: [],
            feedbackStatus: Object.values(feedbackEnums.feedbackTypes).map(
              (x) => ({
                feedbackType: x.toString(),
                feedbackStatusId: feedbackEnums.feedbackStatus.OPEN.toString()
              })
            )
          }
        ],
        processAccess: [
          {
            donorRole: '10',
            feedbackTypeAccess: []
          },
          {
            donorRole: '20',
            feedbackTypeAccess: []
          },
          {
            donorRole: '30',
            feedbackTypeAccess: []
          },
          {
            donorRole: '40',
            feedbackTypeAccess: []
          },
          {
            donorRole: '50',
            feedbackTypeAccess: []
          },
          {
            donorRole: '1000',
            feedbackTypeAccess: []
          }
        ]
      };
      await this.saveWorkflow(newWorkflow);
    }
  }
};
</script>
